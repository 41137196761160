/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import "./App.css";




function App() {
  return (
    <div className="App">
      <div className="container" >
    <img className= "logo-image" src='loogo.svg'  alt="logo" />
    <div className="container-title" >
    <h3 className="title-content">
    أول ابليكشن في مصر  لتوصيل الشحنات أو الطرود الشخصيه خلال ساعتين مع دفع مقدم الشحنه أو تأمين علي الطرد   </h3> 
      </div>
    </div>
    <div className="box" >
          <div className="merchent-box">
          <div className="merchent-title-box" >
  <p className="merchent-title" >عندك شحنه او طرد شخصي حابب توصله حمل تطبيق ويڤو</p>
</div>
          <img className="merchant-image"  src="img-merchant.png" alt="merchant-img" />
          <div className="merchants-btn-container">
          <div className="merchants-btn" >
          <a className="link-btns" href="#">
          <img decoding="async" src="https://weevo.net/wp-content/uploads/2021/09/app-store.svg" class="attachment-full size-full" alt=""/>
        {/* <button disabled className="btn1" > <div>< FaApple style={{fontSize:"30px"}}/> </div> <p> حمل تطبيق ويڤو ايفون</p></button>  */}
        </a>
        </div>
        <div className="merchants-btn" >
        <a className="link-btns" href="https://play.google.com/store/apps/details?id=org.emarketingo.weevo&pcampaignid=web_share">
        <img decoding="async" src="https://weevo.net/wp-content/uploads/2021/09/google-play.svg" class="attachment-full size-full" alt=""/>	
        </a>
        </div>
        </div>
          </div>
        
          <div className="courier-box" >
          <div className="courier-title-box" >
          {/* <div style={{border:"1px solid white",width:"200px", borderRadius:"15px",background:"	#f9f1f1", display:"flex", flexDirection:"column"}}> */}
  <p className="courier-title" >عايز تكون كابتن توصيل حمل ويڤو كابتن</p>
</div>
                  
          <img className="courier-image" src="courier.png" alt="captain-image"/>
          <div className="courier-btn-container">
          <div className="courier-btns" >
          <a className="link-btns" href="#">
          <img decoding="async" src="https://weevo.net/wp-content/uploads/2021/09/app-store.svg" class="attachment-full size-full" alt=""/>
        {/* <button disabled className="btn1" > <div>< FaApple style={{fontSize:"30px"}}/> </div> <p> حمل تطبيق ويڤو ايفون</p></button>  */}
        </a>
        </div>
        <div className="courier-btns">
        <a className="link-btns" href="https://play.google.com/store/apps/details?id=org.emarketingo.courier&pcampaignid=web_share">
        <img decoding="async" src="https://weevo.net/wp-content/uploads/2021/09/google-play.svg" class="attachment-full size-full" alt=""/>	
        </a>
        </div>
        </div>
       
          </div>
        
    </div>
    <div className="website"  >
        <a href="https://weevo.net/">الذهاب لموقع ويڤو</a>
        </div>
    </div> 
  );
}

export default App;
